<template>
	<div class="page-single">
		<van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('推荐规则')" left-arrow
			@click-left="onClickLeft" >
		</van-nav-bar>
		<div class="page-banner">
            <img src="../../assets/pictures/page-banner4.jpg" alt="">
        </div>
        <div class="page-iframe">
			<div class="page-top"></div>
            <div class="page-body" v-html="html">
            </div>
			<div class="page-bottom"></div>
        </div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				show: false,
				name: '',
				html:'',
			}
		},
		methods: {
			onClickLeft() {
				this.$router.go(-1)
			},
			getData() {
				this.$axios
						.get('/help/get_content?type=12')
						.then((res) => {
							this.html = res.data;
						});
			}
		},
		mounted() {
			this.getData();
		}
	}
</script>
